<template>
    <div id="mySupplyChartLine" :style="{height:height, width: width}"></div>
</template>
<script>
// import { validatenull } from '@/tdcommon/validate'

export default {
  name: 'd2-container-echarts-line',
  data () {
    return {
      xAxisData: [],
      seriesTotalNum: [],
      seriesTotalAmount: [],
      seriesTotalProfit: []
    }
  },
  props: {
    height: {
      type: String,
      default: () => ''
    },
    width: {
      type: String,
      default: () => ''
    },
    lineData: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
  },
  methods: {
    initChart (xAxisData, seriesTotalNum, minPrice) {
      // 引入基本模板
      var echarts = require('echarts/lib/echarts')
      // 引入折线图组件
      require('echarts/lib/chart/line')
      // 引入提示框和title组件
      require('echarts/lib/component/tooltip')
      require('echarts/lib/component/title')

      // 清空上一次值
      this.xAxisData = []
      this.seriesTotalNum = []
      // 赋值
      this.xAxisData = xAxisData
      this.seriesTotalNum = seriesTotalNum

      let myChart = echarts.init(document.getElementById('mySupplyChartLine'))
      var options = {
        backgroundColor: '#FFFFFF',
        textStyle: {
          color: '#303133'
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xAxisData,
          axisLine: {
            lineStyle: {
              color: '#909399'
            }
          },
          axisTick: {
            lineStyle: {
              color: '#909399'
            }
          },
          axisLabel: {
            color: '#909399'
          },
          min: -1
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false // 隐藏Y轴的线
          },
          axisTick: {
            show: false // 隐藏Y轴的刻度线
          },
          axisLabel: {
            color: '#909399'
          },
          min: minPrice // 设置Y轴的最小值
        },
        series: [{
          name: '车价',
          type: 'line',
          lineStyle: {
            color: '#409EFF',
            width: 3
          },
          symbol: 'circle',
          symbolSize: 3,
          itemStyle: {
            normal: {
              color: '#409EFF'
            }
          },
          // 设置鼠标悬浮时的样式
          emphasis: {
            symbolSize: 5,
            itemStyle: {
              borderColor: '#FFFFFF', // 鼠标悬浮时边框颜色更改为白色
              borderWidth: 3 // 边框宽度增加
            }
          },
          label: {
            show: true,
            position: 'top'
          },
          data: this.seriesTotalNum
        }]
      }
      myChart.setOption(options, true)
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-echarts-line-supplyhistory/index.vue"
}
</vue-filename-injector>
