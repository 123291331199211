<template>
  <div class="question_answer">
<!--    <div v-if="isExpand" class="search_box">-->
<!--      <div class="search_box_content" @click="dialogVisible = true">-->
<!--        <span>小智问答，尽管问</span>-->
<!--      </div>-->
<!--      <div class="search_box_border"></div>-->
<!--    </div>-->
    <div class="search_span" @click="handleClick">
      <img style="width: 38px;height: 30px;" :src="`${$baseUrl}image/theme/d2/logo/yunXiaoZhi.png`">
      <div class="eye-container">
        <div class="animated-eye"></div>
        <div class="animated-eye"></div>
      </div>
<!--      <img v-if="isExpand" style="width: 41px;height: 41px;" :src="`${$baseUrl}image/theme/d2/logo/intellect-wait.png`">-->
<!--      <img v-else style="width: 41px;height: 41px;" :src="`${$baseUrl}image/theme/d2/logo/intellect-expand.png`">-->
      <span style="margin-left: 5px">小智问答</span>
    </div>
<!--    <el-dialog class="question_answer_dialog" title="" top="50px" v-if="dialogVisible" :visible.sync="dialogVisible" width="75%" :show-close="false">-->
<!--      &lt;!&ndash; iframe 嵌入新的网址 &ndash;&gt;-->
<!--      <iframe-->
<!--          :src="iframeUrl"-->
<!--          width="100%"-->
<!--          height="804px"-->
<!--          frameborder="0"-->
<!--          allowfullscreen>-->
<!--      </iframe>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
export default {
  data () {
    return {
      isExpand: false,
      dialogVisible: false, // 控制对话框的显示与隐藏
      iframeUrl: 'https://zy.ai.56tms.com/174079163118734/apps/174079357743266/' // 你要打开的网址
    }
  },
  methods: {
    handleClick () {
      // this.isExpand = !this.isExpand
      // this.$emit('click')
      window.open('https://zy.ai.56tms.com/174079163118734/apps/174079357743266/')
    }
  }
}
</script>
<style lang="scss">
//@keyframes slideInFromRight {
//  0% {
//    transform: translateX(100%); /* 从屏幕右侧开始 */
//    opacity: 0; /* 开始时不可见 */
//  }
//  100% {
//    transform: translateX(0); /* 移动到屏幕左侧 */
//    opacity: 1; /* 完全可见 */
//  }
//}
// 边框闪烁
@keyframes border-blink {
  0%, 100% {
    border-color: #8280fc; /* 初始和结束的颜色 */
  }
  50% {
    border-color: transparent; /* 中间的颜色，设置为透明 */
  }
}
// 淡入
@keyframes zoomIn {
  0% { opacity: 0; transform: scale(0.5); }
  100% { opacity: 1; transform: scale(1); }
}
@property --range {
  initial-value: 0deg;
  syntax: '<angle>';
  inherits: false;
}
@keyframes rotating {
  0% {
    --range: 0deg;
  }
  100% {
    --range: 360deg;
  }
}
@keyframes eyeMovement {
  0%, 50%, 100% {
    transform: translateX(0); /* 中间位置 */
  }
  25% {
    transform: translateX(-3px); /* 向左移动 */
  }
  75% {
    transform: translateX(3px); /* 向右移动 */
  }
}
.question_answer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 100px;
  .search_box {
    //animation: slideInFromRight 1s ease-out forwards; /* 应用动画 */
    opacity: 0; /* 开始时完全透明 */
    transform: scale(0.5); /* 开始时缩小 */
    animation: zoomIn 1s forwards; /* 应用动画并保持最终状态 */
    position: relative;
  }
  .search_box_content {
    position: absolute;
    cursor: pointer;
    width: 879px;
    height: 28px;
    line-height: 28px;
    padding: 3px 2px 3px 20px;
    color: rgb(245, 154, 35);
    font-family: 'Arial Normal', 'Arial', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    letter-spacing: normal;
    border-radius: 50px;
    background-color: #FFFFFF;
    z-index: 11;
    top: 4px;
    margin-left: 3px;
  }
  .search_box_border {
    position: relative;
    padding: 3px 2px 3px 20px;
    width: 886px;
    height: 36px;
    border-radius: 50px;
    inset: 0;
    z-index: 10;
    //background: repeating-conic-gradient(from var(--range), #0ff, #f0f, #0ff, #ff0, #0f0);
    background: repeating-conic-gradient(from var(--range), #80ffff, #81e6fb, #819ffd, #8280fc, #56a6e5, #f9cb91, #fbff81, #edfd80, #caf982);
    animation: rotating 4s linear infinite;
  }
  .search_span {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    //color: #FACD91;
    color: #02A7F0;
    .eye-container {
      width: 20px;
      height: 9px;
      background-color: #FFFFFF;
      position: absolute;
      margin-left: 9px;
      margin-top: 2px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .animated-eye {
        width: 5px;
        height: 7px;
        background-color: #00ffff;
        border-radius: 99px;
        /* 初始居中定位 */
        position: relative;
        /* 动画配置 */
        animation: eyeMovement 4s ease-in-out infinite;
      }
    }
  }
}
.question_answer_dialog {
  opacity: 0; /* 开始时完全透明 */
  transform: scale(0.5); /* 开始时缩小 */
  animation: zoomIn 1s forwards; /* 应用动画并保持最终状态 */
  .el-dialog {
    border: 2px solid #8280fc; /* 初始边框样式 */
    animation: border-blink 1s infinite; /* 应用动画 */
  }
  .el-dialog__header, .el-dialog__body {
    padding: 0 0;
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-question-answer/index.vue"
}
</vue-filename-injector>
