<template>
  <el-dialog class="trainClass" width="60%" center title="选择地址" v-if="addressVisible" :visible.sync="addressVisible" :before-close="close" append-to-body :close-on-click-modal="false" destroy-on-close>
    <div style="margin-bottom: 10px;">
      <span style="font-size: 12px;">请选择点击地图或直接进行搜索</span>
    </div>
    <div id="container"></div>
    <div class="info">
      <div class="input-item">
        <div class="input-item-prepend">
          <span class="input-item-text" style="width:8rem;">请输入关键字</span>
        </div>
        <input id='tipinput' type="text">
      </div>
    </div>
    <div class="toolbar" v-show="!validateNull(point) && !validateNull(address)">
      当前坐标：{{point[0]}}，{{point[1]}}
      <br>
      地址：{{address}}
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>

import { validatenull } from '@/tdcommon/validate'
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  name: 'd2-container-get-location',
  data () {
    return {
      addressVisible: false,
      map: null, // 地图实例
      marker: null, // 地图icon
      geocoder: null, // 逆解析实例
      address: null, // 地址
      adcode: null,
      district: null,
      center: [116.397428, 39.90923], // 地图中心点
      point: [] // 经纬度
    }
  },
  props: {
  },
  mounted () {
  },
  unmounted () {
    // this.map?.destroy()
  },
  methods: {
    validateNull (val) {
      return validatenull(val)
    },
    show (point, address) {
      if (point && address) {
        this.address = address
        this.point = point
        this.center = point
      } else {
        this.address = null
        this.point = []
        this.center = [116.397428, 39.90923]
      }
      // 打开弹窗
      this.addressVisible = true
      // 地图初始化
      this.initAMap()
    },
    close () {
      this.map = null
      this.marker = null
      this.addressVisible = false
    },
    initAMap () {
      window._AMapSecurityConfig = {
        // securityJsCode: '5b5ad179f534e8e0a563ac5f64b2e22e' // 你申请的安全密钥
        securityJsCode: '' // 你申请的安全密钥
      }
      AMapLoader.reset()
      AMapLoader.load({
        // key: '86d481c9c0d7c9f755431600e1b5eb9e', // 申请好的Web端开发者Key，首次调用 load 时必填
        key: '', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale', 'AMap.Geocoder', 'AMap.Marker', 'AMap.PlaceSearch', 'AMap.AutoComplete'], // 需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: '1.1', // AMapUI 版本
          plugins: ['overlay/SimpleMarker'] // 需要加载的 AMapUI ui 插件
        }
      }).then((AMap) => {
        this.map = new AMap.Map('container', {
          // 设置地图容器id
          // viewMode: '3D', // 是否为3D地图模式
          resizeEnable: true,
          zoom: 16, // 初始化地图级别
          center: this.center // 初始化地图中心点位置
        })
        // 如果父组件传入有效值 回显一个icon
        if (this.point.length > 0) {
          this.addMarker()
        }
        // 监听用户点击地图事件
        this.map.on('click', e => {
          let lng = e.lnglat.lng
          let lat = e.lnglat.lat
          this.point = [lng, lat]
          // 增加点标记
          this.addMarker()
          // 获取地址
          this.getAddress()
        })
        // 输入提示和POI搜索
        this.autoSearch()
      }).catch((e) => {
        console.log('Err', e)
      })
    },
    autoSearch () {
      let self = this
      // 输入提示
      var autoOptions = {
        city: '全国',
        input: 'tipinput'
      }
      var autocomplete = new AMap.AutoComplete(autoOptions)
      // let placeSearch = new AMap.PlaceSearch({
      //   map: self.map
      // })
      // // 注册监听，当选中某条记录时会触发
      autocomplete.on('select', function (e) {
        self.map.clearMap()
        self.point = [e.poi.location.lng, e.poi.location.lat]
        self.address = e.poi.district + e.poi.address
        self.adcode = e.poi.adcode
        self.district = e.poi.district
        self.map.setCenter([e.poi.location.lng, e.poi.location.lat])
        self.addMarker()
      })
    },
    // 添加点标记
    addMarker () {
      // 清除其他icon
      if (this.marker) {
        this.marker.setMap(null)
        this.marker = null
      }
      // 重新渲染icon
      this.marker = new AMap.Marker({
        icon: '',
        position: this.point, // icon经纬度
        offset: new AMap.Pixel(0, 0) // icon中心偏移量
      })
      this.marker.setMap(this.map) // 设置icon
    },
    // 将经纬度转换成地址
    getAddress () {
      const self = this
      // 通过高德的SDK完成
      this.geocoder = new AMap.Geocoder({
        city: '全国', // 逆地理编码时，设置地址描述所在城市，默认全国
        radius: 1000,
        extensions: 'all'
      })
      // 调用逆解析方法
      this.geocoder.getAddress(this.point, (status, result) => {
        if (status === 'complete' && result.info === 'OK') {
          if (result && result.regeocode) {
            self.address = result.regeocode.formattedAddress
            self.adcode = result.regeocode.addressComponent.adcode
            self.district = result.regeocode.addressComponent.province + result.regeocode.addressComponent.city + result.regeocode.addressComponent.district
          }
        }
      })
    },
    handleOk () {
      this.$emit('handleChange', { point: this.point, address: this.address, adcode: this.adcode, district: this.district })
      this.map = null
      this.marker = null
      this.addressVisible = false
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
.trainClass {
  .info {
    padding: 10px 10px;
    margin-bottom: 1rem;
    border-radius: .25rem;
    position: absolute;
    top: 100px;
    background-color: #FFFFFF;
    border-width: 0;
    right: 50px;
    box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
    .input-item {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .input-item-text {
        text-align: justify;
        padding: 8px 8px;
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 400;
        color: #495057;
        text-align: center;
        white-space: nowrap;
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        border-radius: 4px 0 0 4px;
      }
      .input-item-prepend {
        margin-right: -1px;
      }
      #tipinput {
        font-size: 12px;
        padding: 8px 8px;
        color: #495057;
        background-color: #FFFFFF !important;
        border: 1px solid #ced4da;
        border-radius: 0 4px 4px 0;
      }
    }
  }
}
.amap-sug-result {
  z-index: 9999;
}
</style>
<style scoped>
#container{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 500px;
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-get-location/index.vue"
}
</vue-filename-injector>
