import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/basic/dtcustomer/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/basic/dtcustomer',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/basic/dtcustomer/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/basic/dtcustomer/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/basic/dtcustomer',
    method: 'put',
    data: obj
  })
}

/**
 * 手动推送审批消息
 */
export function manuallyPushingApprovalMessages (ids) {
  return request({
    url: '/basic/dtcustomer/manuallyPushingApprovalMessages',
    method: 'post',
    params: ids
  })
}

/**
 * 发货单位 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/basic/dtcustomer/delbyids',
    method: 'post',
    params: ids
  })
}
/**
 * 通过输入客户名称远程搜索客户信息
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function cusName (custname) {
  return request({
    url: '/basic/dtcustomer/getcust',
    method: 'get',
    params: { custname: custname }
  })
}
/**
 * 通过输入客户名称远程搜索客户信息（停用的也能搜索）
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function cusAllName (custname) {
  return request({
    url: '/basic/dtcustomer/getcustall',
    method: 'get',
    params: { custname: custname }
  })
}
/**
 * 发货单位 启用
 */
export function lockenableObjs (ids) {
  return request({
    url: '/basic/dtcustomer/lockenable',
    method: 'post',
    params: ids
  })
}
/**
 * 发货单位 停用
 */
export function lockstopObjs (ids) {
  return request({
    url: '/basic/dtcustomer/lockstop',
    method: 'post',
    params: ids
  })
}
/**
 * 发货单位 判断是否重复
 */
export function getDetails (obj) {
  return request({
    url: '/basic/dtcustomer/details/' + obj,
    method: 'get'
  })
}
/**
 * 营运单导入
 */
export function excelImport (file, id) {
  return request({
    url: '/basic/dtcustomer/import/yyd',
    method: 'post',
    params: { file: file, id: id }
  })
}
/**
 * 营运单导入 返回结果
 */
export function returnImport () {
  return request({
    url: '/basic/dtcustomer/import/progress',
    method: 'get'
  })
}
/**
 * 托运单导入
 */
export function excelImportTyd (file, id) {
  return request({
    url: '/basic/dtcustomer/import/tyd',
    method: 'post',
    params: { file: file, id: id }
  })
}
/**
 * 上传阿里 获取签名
 */
export function uploadAli () {
  return request({
    url: '/tms/ossobject/sign',
    method: 'get'
  })
}
/**
 * 上传阿里 客户资料保存到后台
 */
export function addUploadAli (obj) {
  return request({
    url: '/tms/ossobject/cust',
    method: 'post',
    data: obj
  })
}
/**
 * 上传阿里 客户资料返回数据接口
 */
export function getOssCusList (obj) {
  return request({
    url: '/tms/ossobject/list',
    method: 'get',
    params: { objectNo: obj }
  })
}
/**
 * 删除上传阿里的后台数据
 */
export function delOssData (id) {
  return request({
    url: '/tms/ossobject/' + id,
    method: 'delete'
  })
}
/**
 * 通过ids进行财务审核
 */
export function cusAudit (ids) {
  return request({
    url: '/basic/dtcustomer/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行财务反审核
 */
export function cusUnaudit (ids) {
  return request({
    url: '/basic/dtcustomer/unaudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行终审
 */
export function cusExamine (ids) {
  return request({
    url: '/basic/dtcustomer/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行反终审
 */
export function cusUnexamine (ids) {
  return request({
    url: '/basic/dtcustomer/unexamine',
    method: 'post',
    params: ids
  })
}
/**
 * 上传阿里 批量下载
 */
export function aliBatchDownload (obj) {
  return request({
    url: '/tms/ossobject/aliBatchDownload',
    method: 'post',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 通过ids进行查询合作保证金交易历史
 */
export function getDealHistory (ids) {
  return request({
    url: '/basic/dtcustomer/marginTradingHistory',
    method: 'post',
    params: { ids: ids }
  })
}
/**
 * 发货单位 停用
 */
export function dispatchNameSave (obj) {
  return request({
    url: '/basic/dtcustomer/modifyDispatcherName',
    method: 'post',
    params: obj
  })
}
/**
 * 调度部门下拉 通过部门id获取对应部门的数据
 */
export function getDispatchMsg (departmentId) {
  return request({
    url: '/basic/dtdispatcher/queryDepartmentId/' + departmentId,
    method: 'get'
  })
}
/**
 * 部门下拉 通过部门id获取对应部门下面 驻场 的数据
 */
export function getOnsiteMsg (deptId) {
  return request({
    url: '/tms/hremployee/queryHrEmployeeOnSite/' + deptId,
    method: 'get'
  })
}
/**
 * 客户管理只修改基础资料信息(不需要未审核、未审单状态，保留启用状态)
 */
export function updateBasicData (obj) {
  return request({
    url: '/basic/dtcustomer/updateBasicData',
    method: 'post',
    data: obj
  })
}
/**
 * 部门下拉 通过部门id获取对应部门下面 项目经理、项目主管、客服代表 的数据
 */
export function getProjectMsg (deptId) {
  return request({
    url: '/tms/hremployee/queryCustomerManagementList/' + deptId,
    method: 'get'
  })
}
/**
 * 通过ids进行市场审核
 */
export function cusMarket (ids) {
  return request({
    url: '/basic/dtcustomer/market',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行市场反审核
 */
export function cusUnMarket (ids) {
  return request({
    url: '/basic/dtcustomer/unMarket',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行结算审核
 */
export function cusSettlement (ids) {
  return request({
    url: '/basic/dtcustomer/settlement',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行结算反审核
 */
export function cusUnSettlement (ids) {
  return request({
    url: '/basic/dtcustomer/unSettlement',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行运营审核
 */
export function cusOperate (ids) {
  return request({
    url: '/basic/dtcustomer/operate',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行运营反审核
 */
export function cusUnOperate (ids) {
  return request({
    url: '/basic/dtcustomer/unOperate',
    method: 'post',
    params: ids
  })
}
/**
 * 上传阿里 通过itemType区分 获取返回数据接口
 */
export function getOssObjectList (obj) {
  return request({
    url: '/tms/ossobject/itemType',
    method: 'get',
    params: obj
  })
}
/**
 * 上传阿里 通过itemType区分 获取返回历史记录数据接口
 */
export function getOssDeleteList (obj) {
  return request({
    url: '/tms/ossobject/delList',
    method: 'get',
    params: obj
  })
}
/**
 * 上传阿里 客户合同 保存到后台
 */
export function uploadAliAberrant (obj) {
  return request({
    url: '/tms/ossobject/projectManagementAberrant',
    method: 'post',
    data: obj
  })
}
/**
 * 上传阿里 客户资质 保存到后台
 */
export function uploadAliQualifications (obj) {
  return request({
    url: '/tms/ossobject/customerQualifications',
    method: 'post',
    data: obj
  })
}
/**
 * 上传阿里 确认函 保存到后台
 */
export function uploadAliConfirmationLetter (obj) {
  return request({
    url: '/tms/ossobject/confirmationLetter',
    method: 'post',
    data: obj
  })
}
/**
 * 上传阿里 往来资料 保存到后台
 */
export function uploadAliCurrentInformation (obj) {
  return request({
    url: '/tms/ossobject/currentInformation',
    method: 'post',
    data: obj
  })
}
/**
 * 通过ids进行 启用逾期风控
 */
export function enableCostRisk (ids) {
  return request({
    url: 'EnableCostRisk',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 停用逾期风控
 */
export function stopCostRisk (ids) {
  return request({
    url: '/basic/dtcustomer/stopCostRisk',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 启用应收共享校验
 */
export function enableBillShare (ids) {
  return request({
    url: 'basic/dtcustomer/enableBillShare',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行 停用应收共享校验
 */
export function stopBillShare (ids) {
  return request({
    url: '/basic/dtcustomer/stopBillShare',
    method: 'post',
    params: ids
  })
}
/**
 * 项目管理审批时校验考核毛利率是否与立项报告考核毛利率是否一致
 */
export function searchIntroduceAssessment (ids) {
  return request({
    url: '/tms/tmsIntroduce/selectIntroduceAssessment',
    method: 'post',
    params: ids
  })
}
/**
 * 根据客户管理id查询二级表项目管理中项目名称
 */
export function getEntryName (id) {
  return request({
    url: '/basic/customerproject/queryEntryNameAll/' + id,
    method: 'get'
  })
}
/**
 * 项目名称 弹框选择
 */
export function customerSelect (query) {
  return request({
    url: '/basic/dtcustomer/getcustPage',
    method: 'post',
    data: query
  })
}
/**
 * 通过deptId、customerIds查询过滤下拉项目
 */
export function getCustomer (obj) {
  return request({
    url: '/basic/dtcustomer/queryTargetByCustomer',
    method: 'post',
    params: obj
  })
}
/**
 * 通过deptIds、customerIds查询过滤下拉多个部门对应的项目
 */
export function getCustomerObjs (obj) {
  return request({
    url: '/basic/dtcustomer/queryCustomerByDeptIds',
    method: 'post',
    params: obj
  })
}
/**
 * 根据大区查询项目名称
 * @param cusName 输入搜索信息、areaId 大区ID
 * @returns {AxiosPromise}
 */
export function searchRegionCusName (areaId, cusName) {
  return request({
    url: '/basic/dtcustomer/queryProjectInformationRegion',
    method: 'get',
    params: { areaId: areaId, customerName: cusName }
  })
}
/**
 * 获取收支大类
 */
export function getFeeType () {
  return request({
    url: '/basic/arap/getList',
    method: 'get'
  })
}
/**
 * 获取运单收支大类
 */
export function getYdFeeList () {
  return request({
    url: '/basic/arap/getYdFeeList',
    method: 'get'
  })
}
/**
 * 导出人员分工
 */
export function exportProjectUser (obj) {
  return request({
    url: '/basic/dtcustomer/exportProjectUser',
    responseType: 'blob',
    method: 'post',
    data: obj
  })
}
/**
 * 导出系统闸口
 */
export function exportProjectGate (obj) {
  return request({
    url: '/basic/dtcustomer/exportProjectGate',
    responseType: 'blob',
    method: 'post',
    data: obj
  })
}
/**
 * 推送鸿运链
 */
export function pushHyl (id) {
  return request({
    url: '/basic/dtcustomer/pushHyl',
    method: 'post',
    params: id
  })
}
